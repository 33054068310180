import React from "react"
import BlockArea from "gatsby-plugin-pressless"
import * as Chakra from "@chakra-ui/react"
import HeroImage from "../components/hero-image"
import PageTitle from "../components/blocks/page-title"
import BlogMasonryGrid from "../components/blog-masonry-grid"
import ProjectGrid from "../components/blocks/project-grid"
import { graphql } from "gatsby"
import Section from "../components/section"
import Accordion from "../components/blocks/studio/accordion"
import Form from "../components/blocks/studio/form"
import TextArea from "../components/blocks/studio/text-area"
import Download from "../components/blocks/studio/download"
import { Seo } from "gatsby-plugin-wpgraphql-seo"
import * as htmlEntities from "html-entities"
import OurClients from "../components/blocks/studio/our-clients"
import FullWidthImage from "../components/blocks/full-width-image"
import Image from "../components/blocks/studio/image"
import LargeTextArea from "../components/blocks/studio/large-text-area"
import HeroSlider from "../components/hero-slider"
import RedPlusGrid from "../components/blocks/red-plus-grid"
import RedDigitalGrid from "../components/blocks/red-digital-grid"
import ModeSwitch from "../components/blocks/mode-switch"
import { Helmet } from "react-helmet"
import Rxd from "../components/blocks/rxd";

const WpPage = ({ data, pageContext, location }) => {
    return (
        <Chakra.Flex width="100%" flexGrow={1}>
            <Helmet>
                {location.pathname.includes("/red-digital") && (
                    <style>{`  :root { --background-col: #000; --text-col: #fff; } `}</style>
                )}
            </Helmet>
            {data?.wpPage && (
                <Seo
                    post={data?.wpPage}
                    title={
                        data?.wpPage?.seo?.title
                            ? htmlEntities.decode(data?.wpPage?.seo?.title)
                            : "RED"
                    }
                />
            )}
            <BlockArea
                id="page"
                data={data?.wpPage}
                isEnabled={pageContext.isPressLess}
            >
                <HeroSlider />
                <HeroImage />
                <PageTitle />
                <BlogMasonryGrid />
                <ProjectGrid />
                <Section />
                <Accordion />
                <Form />
                <TextArea />
                <LargeTextArea />
                <Download />
                <OurClients />
                <FullWidthImage />
                <RedPlusGrid />
                <Image />
                <RedPlusGrid />
                <RedDigitalGrid />
                <ModeSwitch />
                <Rxd/>
            </BlockArea>
        </Chakra.Flex>
    )
}

export default WpPage

export const query = graphql`
    query($id: String) {
        site {
            buildTime
        }
        wpPage(id: { eq: $id }) {
            template {
                templateName
            }
            date(formatString: "LL")
            title
            presslesscontent
            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData(
                                quality: 100
                            )
                        }
                    }
                }
            }
            seo {
                breadcrumbs {
                    text
                    url
                }
                title
                metaDesc
                focuskw
                metaKeywords
                opengraphTitle
                opengraphDescription
                opengraphImage {
                    altText
                    sourceUrl
                    srcSet
                }
                twitterTitle
                twitterDescription
                twitterImage {
                    altText
                    sourceUrl
                    srcSet
                }
                canonical
                cornerstone
                schema {
                    articleType
                    pageType
                    raw
                }
            }
        }
    }
`
