/* eslint-disable react/prop-types */
import React from "react"
import * as Chakra from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import formatDate from "../utils/format-date"
import Masonry, { ResponsiveMasonry } from "react-responsive-masonry"
import useMouse from "@react-hook/mouse-position"
import {
  AnimationState,
  useAnimatableLayoutEffect,
} from "./animation-helpers/animation-state"
import { useScrollOpacity } from "../utils/use-scroll-opacity"
import { WPImage } from "./gatsby-image-svg-fallback"
import {ResponsiveVideoImage} from "./video-image";


const BlogMasonryGrid = React.forwardRef((props, ref) => {

  const blogData = useStaticQuery(graphql`
    query {
      site {
        buildTime
      }
      allWpInstagramPost(sort: {fields: date, order: DESC}) {
        nodes {
          title
          date
          slug
          instagramCaption
          instagramPostType
          instagramPostUrl
          instagramMedia
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
              }
            }
          }
        }
      }
      allWpPost(sort: {fields: date, order: DESC}) {
        nodes {
          title
          categories {
            nodes {
              name
            }
          }
          date
          slug
          postContent {
            video
            hoverImage {
              altText
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
              }
            }
          }
          featuredImage {
            node {
              localFile {
                childImageSharp {
                  gatsbyImageData
                }
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const blogAndIgPosts = React.useMemo(() => {
    const blended = [...blogData.allWpInstagramPost.nodes, ...blogData.allWpPost.nodes]
    return blended.sort((a, b) => {
      return new Date(b.date) - new Date(a.date);
    })
  }, [blogData])


  return (
    <Chakra.Box
        ref={ref}
      width="100%"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="transparent"
      overflow="hidden"
    >
      <Chakra.Box
        // overflow="hidden"
        width="100%"
        height="100%"
        display="flex"
        maxWidth="1800px"
        alignItems="flex-start"
        marginLeft="auto"
        paddingTop="49px"
        marginRight="auto"
        paddingLeft="40px"
        paddingRight="40px"
        flexDirection="column"
        paddingBottom="44px"
        backgroundColor="transparent"
        css={{
          "@media (max-width: 767px)": {
            paddingLeft: "20px",
            paddingRight: "20px",
          },
        }}
      >
        <BlogPostsAndIgPosts posts={blogAndIgPosts.slice(0, 9)} />
      </Chakra.Box>
    </Chakra.Box>
  )
})

const InnerComponent = ({ node, index }) => {
  const ref = React.useRef(null)

  const [boxSize, setBoxSize] = React.useState({ width: 0, height: 0 })

  const mouse = useMouse(ref, {
    enterDelay: 100,
    leaveDelay: 100,
  })

  useAnimatableLayoutEffect(() => {
    if (ref?.current) {
      const prop = AnimationState.getPositionOfElement(ref)
      setBoxSize(prop)
    }
  }, [])

  const scrollOp = useScrollOpacity(index * 100)

  return (
    <Chakra.Box
      width="100%"
      display="flex"
      alignItems="flex-start"
      borderTop="1px solid rgba(0, 0, 0, 0.5)"
      paddingTop="30px"
      paddingBottom="30px"
      flexDirection="column"
      css={{
        "@media (max-width: 767px)": {
          paddingBottom: "50px",
        },
        "@media (max-width: 479px)": {
          width: "100%",
        },
      }}
      ref={ref}
    >
      <Chakra.Box width="100%" height="100%" {...scrollOp}>
        <Chakra.Text
          opacity="0.5"
          fontFamily="BeausiteClassic-Clear"
          lineHeight="19px"
          paddingBottom="16px"
          fontSize="16px"
        >
          {node.categories.nodes[0].name} – {formatDate(new Date(node.date))}
        </Chakra.Text>

          <Chakra.Text
            as="div"
            fontSize="64px"
            maxWidth="423px"
            fontFamily="BeausiteClassic-Clear"
            lineHeight="64px"
            letterSpacing="-2%"
            textTransform="uppercase"
            // position="relative"
            css={{
              ".chakra-image, video": { opacity: 0, transition: "0.3s" },
            }}
            _hover={{
              ".chakra-image, video": {
                opacity: 1,
              },
              ".css-animate-path": {
                transform: "var(--mouse-pos)",
                transition: "0.3s"
              }
            }}
            style={{
              "--mouse-pos": mouse.x > 0  ? `translate(${mouse.x - boxSize.width / 2}px, ${
                  mouse.y - boxSize.height / 2
              }px)` : undefined
            }}
          >
            <Chakra.Flex
              //transition="0.3s"
              css={{ transformOrigin: "center center", svg: { width: "100%" } }}
              className="css-animate-path"
              alignItems="center"
              justifyContent="center"
              position="absolute"
              overflow="visible"
              width="100%"
              height="100%"
            >
              <Chakra.Box
                  width="70%"
                  height="100%"
                  maxWidth="1160px"
                  maxHeight="400px"
              >
                <ResponsiveVideoImage
                    video_url={node?.postContent?.video}
                    image={node?.postContent?.hoverImage}
                    display="flex"
                    alignItems="center"
                    justifyContent="center"
                    objectFit="contain"
                    objectPosition="center center"
                />
              </Chakra.Box>
            </Chakra.Flex>
            <Chakra.Box position="relative">{node.title}</Chakra.Box>
          </Chakra.Text>
      </Chakra.Box>
    </Chakra.Box>
  )
}

const BlogPostCard = ({ post, index }) => {
  return (
    <Chakra.Box
      display="flex"
      key={index}
      as={Link}
      to={`/${post.slug}`}
      width="100%"
      alignItems="flex-start"
      flexDirection="row"
      sx={{
        ".zm_i": { transition: "0.5s", transform: "scale(1.0000)" },
        ".zm_t": { transition: "0s", opacity: "0" },
      }}
      _hover={{
        ".zm_i": { mixBlendMode: "overlay" },
        "&:hover": { textDecoration: "none" },
        ".zm_t": { opacity: 1 },
      }}
      justifyContent="space-between"
      css={{
        "@media (max-width: 767px)": {
          flexDirection: "column",
        },
      }}
    >
      <InnerComponent node={post} index={index} />
    </Chakra.Box>
  )
}

const InstagramPostCard = ({ post }) => {

  let content = (<WPImage {...post?.featuredImage?.node} width="100%" height="100%" />)

  // TODO: video proxy is needed
  if (post.instagramPostType === "video" && false) {
    content = (
      <Chakra.Box 
        as="video" 
        allow="accelerometer; autoplay; encrypted-media; gyroscope; picture-in-picture"
        playsInline autoPlay loop muted 
        src={post?.instagramMedia} width="100%" height="100%" objectFit="cover"
      />
    )
  }


  return (
    <Chakra.Box
      display="flex"
      width="100%"
      alignItems="flex-start"
      flexDirection="column"
      borderTop="1px solid rgba(0, 0, 0, 0.5)"
      paddingTop="30px"
      paddingBottom="30px"
    >
      <Chakra.Text
        opacity="0.5"
        fontFamily="BeausiteClassic-Clear"
        lineHeight="19px"
        paddingBottom="16px"
        fontSize="16px"
      >
        Instagram – {formatDate(new Date(post.date))}
      </Chakra.Text>
      <Chakra.Box mt="10px" as="a" href={post.instagramPostUrl}>
        { content }
      </Chakra.Box>
    </Chakra.Box>
  )
}

const BlogPostsAndIgPosts = ({ posts }) => {

  return (
    <ResponsiveMasonry
      columnsCountBreakPoints={{ 350: 1, 750: 2, 900: 3 }}
      style={{ width: "100%" }}
    >
      <Masonry gutter="30px">
        {posts.map((post, index) => {
          if (post.categories) {
            return <BlogPostCard post={post} key={index} index={index} />
          } else {
            return <InstagramPostCard key={index} post={post} />
          }
        })}
      </Masonry>
    </ResponsiveMasonry>
  )
}

BlogMasonryGrid.pressless = {
  name: "Blog Masonry Grid",
  attributes: {},
}

export default BlogMasonryGrid
