import React from "react"
import * as Chakra from "@chakra-ui/react"
import PlusSign from "../../../images/plus-sign.svg"
import MinusSign from "../../../images/minus-sign.svg"
import {PressLessImage} from "../../gatsby-image-svg-fallback";

interface AccordionProps {
  text?: string
  image?: any
  title?: string
}

const Accordion = React.forwardRef(
  ({ text, image, title, defaultIsOpen }: AccordionProps, ref) => {

    return (
      <Chakra.Box
        ref={ref}
        width="100%"
        display="flex"
        position="relative"
        borderTop="1px solid currentColor"
        alignItems="flex-start"
        flexDirection="column"
      >
        <Chakra.Box
          display="flex"
          width="100%"
          alignItems="flex-start"
          paddingTop="31px"
          flexDirection="column"
          backgroundColor="transparent"
        >
          <Chakra.Accordion
            allowToggle
            width="100%"
            defaultIndex={defaultIsOpen ? [0] : undefined}
          >
            <Chakra.AccordionItem border="0" width="100%" px="0" py="0">
              {({ isExpanded }) => (
                <>
                  <Chakra.Box
                    display="flex"
                    width="100%"
                    alignItems="center"
                    marginBottom="24px"
                    justifyContent="space-between"
                  >
                    <Chakra.AccordionButton
                      width="100%"
                      px="0"
                      py="0"
                      transition="0.4s"
                      position="relative"
                      _hover={{
                        backgroundColor: "transparent",
                        "span:after": {
                          opacity: "1",
                        },
                      }}
                      _active={{
                        backgroundColor: "transparent",
                      }}
                      _focus={{
                        backgroundColor: "transparent",
                      }}

                    >
                      <Chakra.Box textAlign="left" width="100%">
                        <Chakra.Box
                          as="span"
                          fontSize="18px"
                          fontFamily="BeausiteClassic-Clear"
                          lineHeight="23px"
                          position="relative"
                          _after={{
                            transition: "0.1s",
                            content: "''",
                            position: "absolute",
                            left: "0",
                            width: "100%",
                            opacity: "0",
                            height: "1px",
                            bottom: "0",
                            backgroundColor: "currentColor",
                          }}
                        >
                          {title}
                        </Chakra.Box>
                      </Chakra.Box>
                      {isExpanded ? <MinusSign /> : <PlusSign />}
                    </Chakra.AccordionButton>
                  </Chakra.Box>
                  <Chakra.AccordionPanel px="0" paddingBottom="60px">
                    <Chakra.Box
                      as="span"
                      fontSize="18px"
                      fontFamily="BeausiteClassic-Clear"
                      lineHeight="26px"
                    >
                      {text}
                    </Chakra.Box>
                    {(image?.src || image?.images) && (
                      <Chakra.Box width="100%" pt={image ? "24px" : "0px"}>
                      <PressLessImage
                        image={image}
                        width="100%"
                        maxHeight="429px"
                        objectFit="cover"
                      />
                      </Chakra.Box>
                    )}
                  </Chakra.AccordionPanel>
                </>
              )}
            </Chakra.AccordionItem>
          </Chakra.Accordion>
        </Chakra.Box>
      </Chakra.Box>
    )
  }
)

Accordion.pressless = {
  name: "Accordion",
  attributes: {
    title: {
      name: "Accordion Title",
      type: "PlainText",
    },
    text: {
      name: "Accordion Content",
      type: "RichText",
    },
    image: {
      name: "Optional Image",
      type: "Image",
    },
    defaultIsOpen: {
      name: "Default Is Open",
      type: "Boolean",
    },
  },
}

export default Accordion
