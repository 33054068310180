import React from "react"
import * as Chakra from "@chakra-ui/react"
import FadeTransition from "../../transitions/fade-transition"
import useMouse from "@react-hook/mouse-position"
import {
  AnimationState,
  useAnimatableLayoutEffect,
} from "../../animation-helpers/animation-state"
import mergeRefs from "react-merge-refs"
import {PressLessImage} from "../../gatsby-image-svg-fallback";

interface DownloadProps {
  fileType: string
  description: string
  file: any
  readLink: string
}

const Download = React.forwardRef((props: DownloadProps, ref) => {
  const mouseRef = React.useRef(null)

  const [boxSize, setBoxSize] = React.useState({ width: 0, height: 0 })

  const mouse = useMouse(mouseRef, {
    enterDelay: 100,
    leaveDelay: 100,
  })

  useAnimatableLayoutEffect(() => {
    if (mouseRef?.current) {
      const prop = AnimationState.getPositionOfElement(mouseRef)
      setBoxSize(prop)
    }
  }, [])

  return (
    <Chakra.Box
      ref={mergeRefs([ref, mouseRef])}
      width="100%"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="transparent"
      css={{
        ".d_image": {
          opacity: 0,
          transition: "0.5s",
        },
      }}
      _hover={{
        ".d_image": {
          opacity: 1,
        },
      }}
    >
      <Chakra.Flex
        className="d_image"
        transition="0.05s"
        maxWidth="549px"
        maxHeight="358px"
        style={{
          transform: `translate(${mouse.x}px, ${
            mouse.y - boxSize.height / 2
          }px)`,
        }}
        css={{ transformOrigin: "center center" }}
        alignItems="center"
        justifyContent="center"
        position="absolute"
        overflow="visible"
        width="100%"
        height="100%"
      >
        <FadeTransition shouldChange={JSON.stringify(props.hoverImage)}>
          <PressLessImage
            width="100%"
            height="100%"
            maxWidth="549px"
            maxHeight="358px"
            objectFit="cover"
            image={props.hoverImage}
          />
        </FadeTransition>
      </Chakra.Flex>

      <Chakra.Box
        position="relative"
        width="100%"
        display="flex"
        alignItems="flex-start"
        flexDirection="column"
        paddingBottom="50px"
      >
        <Chakra.Box
          as="span"
          fontSize="16px"
          fontStyle="normal"
          fontFamily="Beausite 400"
          fontWeight="400"
          lineHeight="19.2px"
          paddingBottom="44px"
        >
          {props.fileType}
        </Chakra.Box>
        <Chakra.Box
          as="span"
          fontSize="64px"
          maxWidth="647px"
          fontFamily="Beausite 400"
          lineHeight="64px"
          paddingBottom="30px"
          textTransform="uppercase"
          css={{
            "@media (max-width: 479px)": {
              fontSize: "48px",
              maxWidth: "100%",
              lineHeight: "48px",
            },
          }}
        >
          {props.description}
        </Chakra.Box>
        <Chakra.Box
          display="flex"
          width="200px"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Chakra.Box
            as="a"
            color="rgba(0, 0, 0, 0.5)"
            fontSize="18px"
            fontStyle="normal"
            fontFamily="Beausite 400"
            fontWeight="400"
            lineHeight="26px"
            paddingBottom="3px"
            href={props.readLink}
          >
            Read
          </Chakra.Box>
          <Chakra.Box
            as="a"
            color="rgba(0,0,0,0.5)"
            fontSize="18px"
            fontStyle="normal"
            fontFamily="Beausite 400"
            fontWeight="400"
            lineHeight="26px"
            textTransform="capitalize"
            {...props.file}
          >
            Download
          </Chakra.Box>
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
})
Download.pressless = {
  name: "Download",
  attributes: {
    description: {
      name: "Description",
      type: "RichText",
    },
    fileType: {
      name: "File Type and Date",
      type: "RichText",
    },
    readLink: {
      name: "Read Link",
      type: "PlainText",
    },
    file: {
      name: "File",
      type: "File",
    },
    hoverImage: {
      name: "Hover Image",
      type: "Image",
    },
  },
}
export default Download
