import React from "react"
import * as Chakra from "@chakra-ui/react"
import { useKeenSlider } from "keen-slider/react"
import "keen-slider/keen-slider.min.css"
import { useInView } from "react-intersection-observer"
import mergeRefs from "react-merge-refs"
import useMouse from "@react-hook/mouse-position"
import { Link, navigate } from "gatsby"
import { ResponsiveVideoImage } from "./video-image"

interface HeroImageProps {
  heroDetails?: {
    projectName: string
    projectDescription: string
    image: any
    projectLink: string
    video_url?: string
  }[]
}

const HeroImage = React.forwardRef((props: HeroImageProps, ref) => {
  const { ref: inViewRef, inView } = useInView({
    threshold: 0.01,
    initialInView: true,
  })

  const [currentSlide, setCurrentSlide] = React.useState(0)
  const [pause, setPause] = React.useState(false)
  const timer = React.useRef()
  const [opacities, setOpacities] = React.useState([])
  const [sliderRef, slider] = useKeenSlider({
    slides: props.heroDetails ? props.heroDetails.length : 0,
    loop: true,
    duration: 1000,
    slideChanged(s) {
      setCurrentSlide(s.details().relativeSlide)
    },
    dragStart: () => {
      setPause(true)
    },
    dragEnd: () => {
      setPause(false)
    },
    move(s) {
      const new_opacities = s.details().positions.map((slide) => slide.portion)
      setOpacities(new_opacities)
    },
  })
  React.useEffect(() => {
    if (sliderRef?.current) {
      sliderRef.current.addEventListener("mouseover", () => {
        setPause(true)
      })
      sliderRef.current.addEventListener("mouseout", () => {
        setPause(false)
      })
    }
  }, [sliderRef])

  React.useEffect(() => {
    timer.current = setInterval(() => {
      if (!pause && slider && slider.next) {
        slider?.next()
      }
    }, 4000)
    return () => {
      clearInterval(timer.current)
    }
  }, [pause, slider])

  const projectLinks = React.useMemo(() => {
    return props?.heroDetails.map((each) => each.projectLink)
  }, [props?.heroDetails])

  const [withLeftMouse, setWithLeftMouse] = React.useState(false)
  const [withRightMouse, setWithRightMouse] = React.useState(false)

  const sectionRef = React.useRef(null)

  const mouse = useMouse(sectionRef, {
    enterDelay: 100,
    leaveDelay: 0,
  })

  return (
    <Chakra.Box
      ref={mergeRefs([ref, inViewRef, sectionRef])}
      width="100%"
      overflow="hidden"
      marginTop="-123px"
      css={{
        "@media (max-width: 991px)": {
          marginTop: "-75px",
        },
      }}
      position="relative"
    >
      <style>
        {inView &&
          `:root { --hero-text-col: #fff; } .primary-header { background-color: transparent; } .primary-header-border { background-color: transparent; }`}
      </style>
      <Chakra.Box
        ref={sliderRef as React.RefObject<HTMLDivElement>}
        className="fader"
        position="relative"
        overflow="hidden"
        height="100vh"
        width="100%"
        onClick={() => {
          // go to page
        }}
      >
        {props?.heroDetails &&
          props.heroDetails.map(
            ({ projectName, projectDescription, image, video_url }, idx) => (

              <Chakra.Box
                width="100%"
                display="flex"
                alignItems="flex-start"
                flexDirection="column"
                backgroundSize="cover"
                backgroundColor="transparent"
                backgroundRepeat="no-repeat"
                backgroundPosition="center"
                height="100vh"
                key={idx}
                style={{ opacity: opacities[idx] }}
                position="absolute"
                top="0"
                cursor="pointer"
                onClick={() => {
                  if (window.self === window.top) {
                    navigate(projectLinks[currentSlide])
                  }
                }}
              >
                <ResponsiveVideoImage
                  image={image}
                  video_url={video_url}
                  width="100%"
                  height="100vh"
                  objectFit="cover"
                  position="absolute"
                  top="50%"
                  left="50%"
                  objectPosition="bottom"
                  transform="translateY(-50%) translateX(-50%)"
                />
                <Chakra.Box
                  position="absolute"
                  top="0"
                  left="0"
                  width="100%"
                  height="100%"
                  display="flex"
                  alignItems="flex-end"
                >
                  <Chakra.Box
                    width="100%"
                    height="auto"
                    display="flex"
                    maxWidth="1800px"
                    alignItems="flex-end"
                    marginLeft="auto"
                    marginRight="auto"
                    paddingLeft="40px"
                    paddingRight="40px"
                    paddingBottom="27px"
                    css={{
                      "@media (max-width: 991px)": {
                        paddingLeft: "20px",
                        paddingRight: "20px",
                      },
                      "@media (max-width: 767px)": {
                        paddingTop: "751px",
                        paddingBottom: "18px",
                      },
                    }}
                  >
                    <Chakra.Link
                      width="100%"
                      height="auto"
                      display="flex"
                      alignItems="flex-start"
                      flexDirection="column"
                      as={Link}
                      to={projectLinks[currentSlide]}
                    >
                      <Chakra.Box
                        as="span"
                        color="#ffffff"
                        fontSize="18px"
                        fontStyle="normal"
                        fontFamily="Beausite 400"
                        fontWeight="400"
                        lineHeight="16px"
                      >
                        {projectName}
                      </Chakra.Box>
                      <Chakra.Box
                        as="span"
                        paddingTop="6px"
                        fontSize="16px"
                        color="#ffffff"
                        fontStyle="normal"
                        fontFamily="Beausite 400"
                        fontWeight="400"
                        lineHeight="16px"
                      >
                        {projectDescription}
                      </Chakra.Box>
                    </Chakra.Link>
                    <Chakra.Box
                      as="span"
                      textAlign="right"
                      fontFamily="Beausite 400"
                      lineHeight="24px"
                      fontSize="16px"
                      css={{
                        "@media (max-width: 767px)": {
                          fontFamily: "BeausiteClassic-Clear",
                        },
                      }}
                      color="#fff"
                    >
                      {currentSlide + 1}/
                      {slider && slider.details && slider.details().size}
                    </Chakra.Box>
                  </Chakra.Box>
                </Chakra.Box>
              </Chakra.Box>
            )
          )}
      </Chakra.Box>
      <Chakra.Box
        position="absolute"
        transition="opacity 0.5s, left 0.05s, top 0.05s"
        style={{
          left: `${mouse.pageX - 10}px`,
          top: `${mouse.pageY - 20}px`,
          opacity: withRightMouse || withLeftMouse ? 1 : 0,
        }}
        color="#fff"
        fontSize="30px"
      >
        {withRightMouse && `⟶`}
        {withLeftMouse && `⟵`}
      </Chakra.Box>
      <Chakra.Box
        position="absolute"
        onClick={() => {
          if (slider && slider.prev) {
            slider?.prev()
          }
        }}
        width="25%"
        height="85%"
        top="0"
        onMouseOver={() => setWithLeftMouse(true)}
        onMouseOut={() => setWithLeftMouse(false)}
      />
      <Chakra.Box
        position="absolute"
        onClick={() => {
          if (slider && slider.next) {
            slider?.next()
          }
        }}
        width="25%"
        height="85%"
        top="0"
        right="0"
        onMouseOver={() => setWithRightMouse(true)}
        onMouseOut={() => setWithRightMouse(false)}
      />
    </Chakra.Box>
  )
})

HeroImage.displayName = "HeroImage"

HeroImage.pressless = {
  name: "Hero Slider",
  attributes: {
    heroDetails: {
      name: "Slides",
      type: "Repeater",
      attributes: {
        projectName: {
          name: "Project Name",
          type: "RichText",
          placeholder: "Cardinia Hotel",
        },
        projectDescription: {
          name: "Project Description",
          type: "RichText",
          placeholder: "Lorem Ipsum Doles",
        },
        image: {
          name: "Background Image",
          type: "Image",
        },
        video_url: {
          name: "Video (URL)",
          type: "PlainText",
        },
        projectLink: {
          name: "Project Link",
          type: "PlainText",
        },
      },
    },
  },
}

export default HeroImage
