import * as Chakra from "@chakra-ui/react"

export const useScrollOpacity = (delay?: number) => {
  const animation = Chakra.keyframes`
        0% {
            opacity: 0;
            transform: translateY(10px);
        }
        100% {
            opacity: 1;
            transform: translateY(0px);
        }
    `

  return {
    animation: `${animation} 0.7s forwards`,
    style: {
      opacity: 0,
      transform: "translateY(10px)",
      transition: "opacity 0.7s, transform 0.7s",
      animationDelay: delay ? `${delay}ms` : "0",
    },
  }
}
