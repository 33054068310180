import React from "react"
import * as Chakra from "@chakra-ui/react"

interface SectionProps {
  text?: string
  asSticky?: boolean
  children: React.ReactElement | React.ReactElement[]
}

const Section = React.forwardRef((props: SectionProps, ref) => {
  const { text, children, ...otherProps } = props

  const animation = Chakra.keyframes`
        0% {
            opacity: 0;
            transform: translateX(-10px);
        }
        100% {
            opacity: 1;
            transform: translateX(0px);
        }
    `

  return (
    <Chakra.Box
      ref={ref}
      width="100%"
      display="flex"
      position="relative"
      borderTop="1px solid #0008"
      alignItems="flex-start"
      flexDirection="column"
      overflow="hidden"
      {...otherProps}
      _first={{
        borderTop: "none",
      }}
    >
      <Chakra.Box
        display="flex"
        width="100%"
        maxWidth="1800px"
        alignItems="flex-start"
        marginLeft="auto"
        paddingTop="31px"
        marginRight="auto"
        paddingLeft="40px"
        paddingRight="40px"
        flexDirection="column"
        paddingBottom="36px"
        css={{
          "@media (max-width: 767px)": {
            paddingLeft: "20px",
            paddingRight: "20px",
          },
        }}
      >
        <Chakra.Box
          display="flex"
          width="100%"
          alignItems="flex-start"
          flexDirection="column"
        >
          <Chakra.Box
            fontSize="18px"
            fontFamily="BeausiteClassic-Clear"
            lineHeight="24px"
            marginRight="10px"
            position="relative"
            top="0"
            opacity="0"
            css={{
              "@media (max-width: 767px)": {
                position: "relative",
                top: "unset",
              },
              animation: `${animation} 0.7s forwards`,
            }}
          >
            {text}
          </Chakra.Box>
          <Chakra.Box
            display="flex"
            width="100%"
            maxWidth="668px"
            marginTop={text ? "-24px" : "0"}
            minHeight="40px"
            alignItems="flex-start"
            marginLeft="auto"
            marginRight="auto"
            flexDirection="column"
            opacity="0"
            css={{
                "@media (max-width: 1100px)": {
                    marginTop: "24px",
                    maxWidth: "100%",
                },
              animation: `${animation} 0.7s forwards`,
              animationDelay: "0.4s",
            }}
          >
            {props.children}
          </Chakra.Box>
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
})

Section.pressless = {
  name: "Page Section",
  attributes: {
    text: {
      name: "Category Name",
      type: "RichText",
    },
  },
  hasChildren: true,
}
export default Section
