import React from "react"
import * as Chakra from "@chakra-ui/react"

interface PageTitleProps {
  text?: string
}

const PageTitle = React.forwardRef((props: PageTitleProps, ref) => {
  return (
    <Chakra.Box
      width="100%"
      display="flex"
      position="relative"
      alignItems="center"
      flexDirection="column"
      backgroundColor="transparent"
      ref={ref}
    >
      <Chakra.Box
        width="100%"
        display="flex"
        maxWidth="1800px"
        alignItems="flex-start"
        marginLeft="auto"
        paddingTop="44px"
        marginRight="auto"
        paddingLeft="40px"
        paddingRight="40px"
        flexDirection="column"
        css={{
          "@media (max-width: 767px)": {
            paddingLeft: "20px",
            paddingRight: "20px",
            paddingTop: "20px",
            flexDirection: "column",
          },
          "@media (max-width: 479px)": {
            marginLeft: "auto",
            marginRight: "auto",
          },
        }}
      >
        <Chakra.Box
          as="span"
          width="100%"
          fontSize="36px"
          maxWidth="640px"
          fontFamily="Beausite 400"
          lineHeight="43.2px"
          css={{
            "@media (max-width: 767px)": {
              width: "100%",
              height: "auto",
              fontSize: "24px",
              alignSelf: "left",
              textAlign: "left",
              lineHeight: "28.8px",
              paddingTop: "17px",
            },
            "@media (max-width: 479px)": {
              width: "100%",
              maxWidth: "100%",
              fontFamily: "BeausiteClassic-Clear",
            },
          }}
        >
          {props.text}
        </Chakra.Box>
      </Chakra.Box>
    </Chakra.Box>
  )
})

PageTitle.pressless = {
  name: "Page Title",
  attributes: {
    text: {
      name: "Page Title",
      type: "RichText",
      placeholder:
        "Errum faccus et landanti officipsa que pedis volenim aximi, sequam eum con rerfere rioriaecea esendis es si re.",
    },
  },
}

export default PageTitle
