import React from "react"
import * as Chakra from "@chakra-ui/react"
import { useStaticQuery, graphql, Link } from "gatsby"
import { useScrollOpacity } from "../../utils/use-scroll-opacity"
import useMouse from "@react-hook/mouse-position"
import {
  AnimationState,
  useAnimatableLayoutEffect,
} from "../animation-helpers/animation-state"
import { WPImage } from "../gatsby-image-svg-fallback"

const RedDigitalGrid = React.forwardRef((_props, ref) => {
  const projectData = useStaticQuery(graphql`
    query {
      allWpDigital(sort: {fields: date, order: DESC}) {
        nodes {
          title
          link
          featuredImage {
            node {
              localFile {
                publicURL
              }
            }
          }
        }
      }
    }
  `)

  const chunkArray = (arr, value) => {
    const finalArray = []
    for (let i = 0; i < arr.length; i += value) {
      finalArray.push(arr.slice(i, value + i))
    }
    return finalArray
  }

  const chunkedCards = React.useMemo(
    () => chunkArray(projectData.allWpDigital.nodes, 5),
    []
  )

  return (
    <Chakra.Box
      ref={ref}
      width="100%"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="transparent"
    >
      {chunkedCards.map((chunkedSet, index) => (
        <Chakra.Box
          width="100%"
          display="flex"
          maxWidth="1800px"
          alignItems="flex-start"
          marginLeft="auto"
          marginRight="auto"
          paddingLeft="40px"
          _first={{ paddingTop: "40px" }}
          paddingRight="40px"
          flexDirection="column"
          _last={{ paddingBottom: "40px" }}
          css={{
            "@media (max-width: 767px)": {
              paddingLeft: "20px",
              paddingRight: "20px",
            },
          }}
          backgroundColor="transparent"
        >
          <Chakra.Box
            width="100%"
            display="flex"
            flexFlow="wrap"
            justifyContent="space-between"
            backgroundColor="transparent"
          >
            <Chakra.Box
              width="calc(33.333333% - 29px)"
              height="auto"
              display="flex"
              maxHeight="calc(60vh+30px)"
              alignItems="space-between"
              flexDirection="column"
              css={{
                "@media (max-width: 767px)": {
                  width: "100%",
                },
              }}
            >
              {chunkedSet[0] && (
                <ProjectGridItem
                  height="20vh"
                  flexWrap="wrap"
                  objectFit="cover"
                  {...chunkedSet[0]}
                  index={index}
                />
              )}
              {chunkedSet[1] && (
                <ProjectGridItem
                  height="40vh"
                  flexWrap="wrap"
                  objectFit="cover"
                  css={{
                    "@media (max-width: 479px)": {
                      height: "40vh",
                    },
                  }}
                  {...chunkedSet[1]}
                  index={index + 1}
                />
              )}
            </Chakra.Box>
            <Chakra.Box
              width="calc(67.777777% - 29px)"
              height="auto"
              display="flex"
              maxHeight="calc(60vh+30px)"
              alignItems="space-between"
              flexDirection="column"
              css={{
                "@media (max-width: 767px)": {
                  width: "100%",
                },
              }}
            >
              {chunkedSet[2] && (
                <ProjectGridItem
                  width="100%"
                  height="40vh"
                  flexWrap="wrap"
                  objectFit="cover"
                  css={{
                    "@media (max-width: 767px)": {
                      width: "100%",
                    },
                    "@media (max-width: 479px)": {
                      height: "40vh",
                    },
                  }}
                  {...chunkedSet[2]}
                  index={index + 2}
                />
              )}
              <Chakra.Box
                width="100%"
                display="flex"
                alignItems="flex-start"
                justifyContent="space-between"
                css={{
                  "@media (max-width: 767px)": {
                    flexDirection: "column",
                  },
                }}
              >
                {chunkedSet[3] && (
                  <ProjectGridItem
                    width="50%"
                    height="20vh"
                    objectFit="cover"
                    marginRight="20px"
                    css={{
                      "@media (max-width: 767px)": {
                        width: "100%",
                        marginRight: 0,
                      },
                    }}
                    {...chunkedSet[3]}
                    index={index + 3}
                  />
                )}
                {chunkedSet[4] && (
                  <ProjectGridItem
                    width="50%"
                    height="20vh"
                    objectFit="cover"
                    marginLeft="20px"
                    css={{
                      "@media (max-width: 767px)": {
                        width: "100%",
                        marginLeft: 0,
                      },
                    }}
                    {...chunkedSet[4]}
                    index={index + 4}
                  />
                )}
              </Chakra.Box>
            </Chakra.Box>
          </Chakra.Box>
        </Chakra.Box>
      ))}
    </Chakra.Box>
  )
})

RedDigitalGrid.displayName = "ProjectGrid"

const ProjectGridItem = ({ featuredImage, title, link, index, ...props }) => {
  const scrollOp = useScrollOpacity(index * 100)

  return (
    <Chakra.Box
      width="100%"
      maxWidth="100%"
      marginBottom="40px"
      as={Link}
      to={link}
      sx={{
        ".zm_i": { transition: "0.5s", transform: "scale(1.0000)" },
        ".zm_t": { transition: "0s", opacity: "0" },
        "@media (max-width: 767px)": {
          height: "350px!important",
        },
      }}
      _hover={{
        ".zm_i": { mixBlendMode: "overlay" },
        "&:hover": { textDecoration: "none" },
        ".zm_t": { opacity: 1 },
      }}
      {...props}
      {...scrollOp}
    >
      <Chakra.Box
        width="100%"
        height="100%"
        overflow="hidden"
        position="relative"
        backgroundColor="#4b1427"
      >
        <Chakra.Box
          className="zm_i"
          overflow="hidden"
          width="100%"
          height="100%"
        >
          {featuredImage && (
            <WPImage
              alt=""
              overflow="hidden"
              {...featuredImage.node}
              width="100%"
              height="100%"
              objectFit="cover"
              transition="0.3s"
            />
          )}
        </Chakra.Box>
        <Chakra.Flex
          alignItems="center"
          justifyContent="center"
          className="zm_t"
          color="#fff"
          position="absolute"
          height="100%"
          width="100%"
          top="0"
        >
          <Chakra.Text
            as="div"
            fontFamily="BeausiteClassic-Light"
            fontSize="min(12vw, 36px)"
            lineHeight="min(10vw, 36px)"
            letterSpacing="-0.4px"
            textTransform="capitalize"
            textAlign="center"
          >
            {title}
          </Chakra.Text>
        </Chakra.Flex>
      </Chakra.Box>
    </Chakra.Box>
  )
}

RedDigitalGrid.pressless = {
  name: "Red Digital Grid",
  attributes: {},
}
export default RedDigitalGrid
