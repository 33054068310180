import React from "react"
import * as Chakra from "@chakra-ui/react"
import { Wysiwyg } from "../../wysiwyg"

interface TextAreaProps {
  text?: string
}

const TextArea = React.forwardRef((props: TextAreaProps, ref) => {
  return (
    <Chakra.Box
      ref={ref}
      width="100%"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="transparent"
      minHeight="60px"
    >
      <Wysiwyg>{props.text}</Wysiwyg>
    </Chakra.Box>
  )
})

TextArea.pressless = {
  name: "Text Area",
  attributes: {
    text: {
      name: "Text",
      type: "RichText",
    },
  },
}
export default TextArea
