import React from "react"
import * as Chakra from "@chakra-ui/react"

export const Wysiwyg = (props: Chakra.BoxProps) => {
  return (
    <Chakra.Box
      as="span"
      fontFamily="BeausiteClassic-Clear"
      css={{
        h1: {
          fontSize: "30px",
          lineHeight: "38px",
          paddingBottom: "60px",
        },
        h2: {
          fontSize: "20px",
          lineHeight: "20px",
          paddingBottom: "40px",
        },
        p: {
          fontSize: "18px",
          lineHeight: "25px",
          paddingBottom: "28px",
        },
        span: {
          fontSize: "18px",
          lineHeight: "23px",
          paddingBottom: "10px",
          display: "inline-block",
        },
        "ul, ol": {
          fontSize: "18px",
          lineHeight: "23px",
          width: "fit-content",
          margin: "auto",
        },
        li: {
          fontSize: "18px",
          lineHeight: "23px",
          marginLeft: "20px",
          paddingBottom: "10px",
        },
        a: {
          textDecoration: "none",
          color: "#0008",
          transition: "0.3s",
          "&:hover": {
            textDecoration: "none",
            color: "#000",
          },
          position: "relative",
          "&::after": {
            content: '""',
            position: "absolute",
            left: "50%",
            height: "2px",
            width: "0",
            bottom: "-3px",
            backgroundColor: "#0008",
            transition: "0.3s",
          },
          "&:hover::after": {
            left: "0%",
            width: "100%",
            backgroundColor: "#000",
          },
        },
      }}
      {...props}
    />
  )
}
