import React from "react"
import * as Chakra from "@chakra-ui/react"

interface TextAreaProps {
  text?: string
}

const LargeTextArea = React.forwardRef((props: TextAreaProps, ref) => {
  return (
    <Chakra.Box
      ref={ref}
      width="100%"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="transparent"
      minHeight="60px"
      css={{
        fontSize: "30px",
        lineHeight: "38px",
        paddingBottom: "60px",
          "a": {
            textDecoration: "underline"
          }
      }}
    >
      {props.text}
    </Chakra.Box>
  )
})

LargeTextArea.pressless = {
  name: "Large Text Area",
  attributes: {
    text: {
      name: "Text",
      type: "RichText",
    },
  },
}
export default LargeTextArea
