import React from "react"
import * as Chakra from "@chakra-ui/react"
import { graphql, Link, useStaticQuery } from "gatsby"
import { useScrollOpacity } from "../../utils/use-scroll-opacity"
import { WPImage } from "../gatsby-image-svg-fallback"

const ProjectGrid = React.forwardRef(({ categoryName, CategoryTagSelector }, ref) => {
    const projectsData = useStaticQuery(graphql`
      query {
        site {
          buildTime
        }
        allWpProject(sort: {fields: date, order: DESC}) {
          nodes {
            title
            date
            link
            featuredImage {
                node {
                    altText
                    localFile {
                        childImageSharp {
                            gatsbyImageData
                        }
                    }
                }
            }
            categories {
              nodes {
                name
              }
            }
            tags {
              nodes {
                name
              }
            }
          }
        }
      }
    `)


    const filteredProjects = React.useMemo(() => {
      if (CategoryTagSelector === "Category" && categoryName) {
          return projectsData.allWpProject.nodes.filter(
              (x) => {
                  return x.categories?.nodes?.some((category) => category?.name === categoryName)
              })
      }
      if (CategoryTagSelector === "Tag" && categoryName) {
        return projectsData.allWpProject.nodes.filter(
          (x) => {
            return x.tags?.nodes?.some((category) => category?.name === categoryName)
          })
      }
      return projectsData.allWpProject.nodes
    }, [categoryName, CategoryTagSelector])

    const chunkArray = (arr, value) => {
      const finalArray = []
      for (let i = 0; i < arr.length; i += value) {
        finalArray.push(arr.slice(i, value + i))
      }
      return finalArray
    }
    const chunkedCards = React.useMemo(() => chunkArray(filteredProjects, 12), [categoryName, CategoryTagSelector])

    return (
      <Chakra.Box
        width="100%"
        display="flex"
        position="relative"
        alignItems="flex-start"
        flexDirection="column"
        backgroundColor="transparent"
        minHeight="200px"
        ref={ref}
      >
        {chunkedCards.map((chunkedSet, index) => (
          <Chakra.Box
            width="100%"
            display="flex"
            maxWidth="1800px"
            alignItems="flex-start"
            marginLeft="auto"
            _first={{ paddingTop: "40px" }}
            marginRight="auto"
            paddingLeft="40px"
            paddingRight="40px"
            flexDirection="column"
            css={{
              "@media (max-width: 1160px)": {
                paddingLeft: "20px",
                paddingRight: "20px",
              },
            }}
            paddingBottom="0"
            _last={{ paddingBottom: "40px" }}
            backgroundColor="transparent"
            key={index}
          >
            <Chakra.Box
              width="100%"
              display="flex"
              flexFlow="wrap"
              justifyContent="space-between"
              backgroundColor="transparent"
            >
              {chunkedSet[0] && (
                <ProjectGridItem
                  width="calc(33.333333% - 29px)"
                  height="max(300px, 20vh)"
                  flexWrap="wrap"
                  objectFit="cover"
                  css={{
                    "@media (max-width: 1160px)": {
                      width: "100%",
                    },
                  }}
                  {...chunkedSet[0]}
                  index={index}
                />
              )}
              {chunkedSet[1] && (
                <ProjectGridItem
                  width="calc(33.333333% - 29px)"
                  height="max(300px, 20vh)"
                  flexWrap="wrap"
                  objectFit="cover"
                  css={{
                    "@media (max-width: 1160px)": {
                      width: "100%",
                    },
                  }}
                  {...chunkedSet[1]}
                  index={index + 1}
                />
              )}
              {chunkedSet[2] && (
                <ProjectGridItem
                  width="calc(33.333333% - 29px)"
                  height="max(300px, 20vh)"
                  flexWrap="wrap"
                  objectFit="cover"
                  css={{
                    "@media (max-width: 1160px)": {
                      width: "100%",
                    },
                  }}
                  {...chunkedSet[2]}
                  index={index + 2}
                />
              )}
              <Chakra.Box
                width="calc(33.333333% - 29px)"
                height="auto"
                display="flex"
                alignItems="space-between"
                flexDirection="column"
                css={{
                  "@media (max-width: 1160px)": {
                    width: "100%",
                  },
                }}
              >
                {chunkedSet[3] && (
                  <ProjectGridItem
                    height="max(300px, 20vh)"
                    flexWrap="wrap"
                    objectFit="cover"
                    {...chunkedSet[3]}
                    index={index + 3}
                  />
                )}
                {chunkedSet[4] && (
                  <ProjectGridItem
                    height="max(300px, 20vh)"
                    flexWrap="wrap"
                    objectFit="cover"
                    {...chunkedSet[4]}
                    index={index + 4}
                  />
                )}
              </Chakra.Box>
              {chunkedSet[5] && (
                <ProjectGridItem
                  width="calc(33.333333% - 29px)"
                  height="max(640px, calc(40vh + 40px))"
                  flexWrap="wrap"
                  objectFit="cover"
                  css={{
                    "@media (max-width: 1160px)": {
                      width: "100%",
                    },
                  }}
                  {...chunkedSet[5]}
                  index={index + 5}
                />
              )}
              <Chakra.Box
                width="calc(33.333333% - 29px)"
                height="auto"
                display="flex"
                alignItems="space-between"
                flexDirection="column"
                css={{
                  "@media (max-width: 1160px)": {
                    width: "100%",
                  },
                }}
              >
                {chunkedSet[6] && (
                  <ProjectGridItem
                    height="max(300px, 20vh)"
                    flexWrap="wrap"
                    objectFit="cover"
                    {...chunkedSet[6]}
                    index={index + 6}
                  />
                )}
                {chunkedSet[7] && (
                  <ProjectGridItem
                    height="max(300px, 20vh)"
                    flexWrap="wrap"
                    objectFit="cover"
                    {...chunkedSet[7]}
                    index={index + 7}
                  />
                )}
              </Chakra.Box>
              {chunkedSet[8] && (
                <ProjectGridItem
                  width="calc(66.666666% - 16px)"
                  height="max(600px, 40vh)"
                  flexWrap="wrap"
                  objectFit="cover"
                  css={{
                    "@media (max-width: 1160px)": {
                      width: "100%",
                    },
                  }}
                  {...chunkedSet[8]}
                  index={index + 8}
                />
              )}
              {chunkedSet[9] && (
                <ProjectGridItem
                  width="calc(33.333333% - 29px)"
                  height="max(600px, 40vh)"
                  flexWrap="wrap"
                  objectFit="cover"
                  css={{
                    "@media (max-width: 1160px)": {
                      width: "100%",
                    },
                  }}
                  {...chunkedSet[9]}
                  index={index + 9}
                />
              )}
              {chunkedSet[10] && (
                <ProjectGridItem
                  width="calc(33.333333% - 29px)"
                  height="max(300px, 20vh)"
                  flexWrap="wrap"
                  objectFit="cover"
                  css={{
                    "@media (max-width: 1160px)": {
                      width: "100%",
                    },
                  }}
                  {...chunkedSet[10]}
                  index={index + 10}
                />
              )}
              {chunkedSet[11] && (
                <ProjectGridItem
                  width="calc(66.666666% - 16px)"
                  height="max(300px, 20vh)"
                  flexWrap="wrap"
                  objectFit="cover"
                  css={{
                    "@media (max-width: 1160px)": {
                      width: "100%",
                    },
                  }}
                  {...chunkedSet[11]}
                  index={index + 11}
                />
              )}
            </Chakra.Box>
          </Chakra.Box>
        ))}
      </Chakra.Box>
    )
  }
)

ProjectGrid.displayName = "ProjectGrid"

const ProjectGridItem = ({ featuredImage, title, link, index, ...props }) => {
  const scrollOp = useScrollOpacity(index * 100)

  return (
    <Chakra.Box
      width="100%"
      maxWidth="100%"
      as={Link}
      to={link}
      marginBottom="40px"
      sx={{
        ".zm_i": { transition: "0.5s", transform: "scale(1.0000)" },
        ".zm_t": { transition: "0s", opacity: "0" },
        "@media (max-width: 1160px)": {
          height: "350px!important",
        },
      }}
      _hover={{
        ".zm_i": { mixBlendMode: "overlay" },
        "&:hover": { textDecoration: "none" },
        ".zm_t": { opacity: 1 },
      }}
      {...props}
      {...scrollOp}
    >
      <Chakra.Box
        width="100%"
        height="100%"
        overflow="hidden"
        position="relative"
        backgroundColor="#4b1427"
      >
        {featuredImage && (
          <WPImage
            alt=""
            className="zm_i"
            overflow="hidden"
            {...featuredImage.node}
            width="100%"
            height="100%"
            objectFit="cover"
          />
        )}
        <Chakra.Flex
          alignItems="center"
          justifyContent="center"
          className="zm_t"
          color="#fff"
          position="absolute"
          height="100%"
          width="100%"
          top="0"
        >
          <Chakra.Text
            as="div"
            fontFamily="BeausiteClassic-Light"
            fontSize="min(12vw, 36px)"
            lineHeight="min(10vw, 36px)"
            letterSpacing="-0.4px"
            textTransform="capitalize"
            textAlign="center"
          >
            {title}
          </Chakra.Text>
        </Chakra.Flex>
      </Chakra.Box>
    </Chakra.Box>
  )
}

ProjectGrid.pressless = {
  name: "Project Grid",
  attributes: {
    categoryName: {
      name: "Category Name or Tag Name (optional)",
      description:
        "You may define a category name to filter these items on different pages. It MUST be identical to the category name used in WordPress.",
      type: "PlainText",
    },
    CategoryTagSelector: {
      name: "Category Tag Selector",
      type: "Select",
      options: [{ Category: "Category" }, { Tag: "Tag" }],
    },
  },
}

export default ProjectGrid
