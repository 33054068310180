import React, { useState } from "react"
import * as Chakra from "@chakra-ui/react"
import addToMailchimp from 'gatsby-plugin-mailchimp'

interface FormProps {
  button?: string
  email?: string
  header?: string
  updates?: string
}

const Form = React.forwardRef((props: FormProps, ref) => {
  const [email, setEmail] = useState("")
  const [isLoading, setIsLoading] = React.useState(false)

  const handleSubmit = async (e) => {
    e.preventDefault()
    setIsLoading(true)
    let data = {}
    const result = await addToMailchimp(email, data)
    setIsLoading(false)
    setEmail("")
  }

  return (
    <Chakra.Box
      ref={ref}
      width="100%"
      display="flex"
      position="relative"
      maxWidth="100%"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="transparent"
      css={{
        "@media (max-width: 767px)": {
          maxWidth: "100%",
        },
      }}
    >
      <Chakra.Box
        width="100%"
        display="flex"
        alignItems="flex-start"
        borderColor="rgba(0, 0, 0, 0.5)"
        borderWidth="1px"
        flexDirection="column"
        paddingBottom="51px"
        borderTopWidth="0px"
        borderLeftWidth="0px"
        borderRightWidth="0px"
      >
        <Chakra.Box
          as="span"
          fontSize="36px"
          fontFamily="BeausiteClassic-Clear"
          lineHeight="44px"
          letterSpacing="-1px"
        >
          Would you like to stay in touch with trends and news in retail and
          hospitality? Join us now to get regular updates on design, branding
          and digital innovations.
        </Chakra.Box>
      </Chakra.Box>
      <Chakra.Box
        display="flex"
        width="100%"
        alignItems="flex-start"
        paddingTop="31px"
        flexDirection="column"
      >
        <Chakra.Box
          as="span"
          fontSize="18px"
          lineHeight="23px"
          paddingBottom="24px"
        >
          Get Updates
        </Chakra.Box>
        <form
          onSubmit={handleSubmit}
          style={{ width: "100%" }}
        >
          <Chakra.Box
            width="100%"
            display="flex"
            alignItems="flex-start"
            flexDirection="row"
            justifyContent="space-between"
            pb="17px"
          >
            <Chakra.Input
              borderColor="transparent"
              fontSize="18px"
              textIndent="0px !important"
              width="100%"
              padding="0px !important"
              _focus={{ borderColor: "transparent" }}
              _hover={{ borderColor: "transparent" }}
              fontFamily="BeausiteClassic-Clear"
              lineHeight="26px"
              placeholder="Email address"
              value={email}
              name="email"
              required
              onChange={(e) => setEmail(e.target.value)}
              _placeholder={{
                fontSize: "18px",
                padding: "0px",
                lineHeight: "26px",
                color: "rgba(0,0,0,0.5)",
              }}
            />
            <Chakra.Button
              type="submit"
              fontSize="18px"
              lineHeight="26px"
              bg="transparent"
              fontWeight="500"
              _hover={{ bg: "transparent" }}
              _focus={{ bg: "transparent" }}
              _active={{ background: "transparent" }}
              isLoading={isLoading}
            >
              Submit
            </Chakra.Button>
          </Chakra.Box>
        </form>
      </Chakra.Box>
    </Chakra.Box>
  )
})

Form.pressless = {
  name: "Email Signup Form",
  attributes: {
    header: {
      name: "CTA Text",
      type: "RichText",
      placeholder: `Would you like to stay in touch with trends and news in retail and
      hospitality? Join us now to get regular updates on design, branding
      and digital innovations.`,
    },
    updates: {
      name: "Get Updates Text",
      type: "RichText",
      placeholder: "Get Updates",
    },
    button: {
      name: "Submit",
      type: "RichText",
    },
  },
}
export default Form
