import React from "react"
import * as Chakra from "@chakra-ui/react"
import {PressLessImage} from "../../gatsby-image-svg-fallback";

interface ImageProps {
  image: any
}

const Image = React.forwardRef((props: ImageProps, ref) => {
  return (
    <Chakra.Box
      ref={ref}
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="transparent"
      css={{
        "@media (max-width: 767px)": {
          width: "100%",
        },
        "@media (max-width: 479px)": {
          width: "100%",
        },
      }}
      minHeight="100px"
    >
      <Chakra.Box
        display="flex"
        width="100%"
        alignItems="center"
        flexDirection="column"
        justifyContent="center"
      >
        <PressLessImage
          image={props.image}
          width="100%"
          maxHeight="414px"
          objectFit="cover"
          css={{
            "@media (max-width: 767px)": {
              width: "100%",
            },
          }}
        />
      </Chakra.Box>
    </Chakra.Box>
  )
})

Image.pressless = {
  name: "Image",
  attributes: {
    image: {
      name: "Image",
      type: "Image",
    },
  },
}

export default Image
