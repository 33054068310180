import React from "react"
import * as Chakra from "@chakra-ui/react"
import useMouse from "@react-hook/mouse-position"
import {
  AnimationState,
  useAnimatableLayoutEffect,
} from "../../animation-helpers/animation-state"
import FadeTransition from "../../transitions/fade-transition"
import { PressLessImage } from "../../gatsby-image-svg-fallback"
import { Link } from "gatsby"

interface OurClientsProps {
  clients?: { client: string; categoryLink: string; image: any }[]
}

const OurClients = React.forwardRef((props: OurClientsProps, ref) => {
  const [hoverImage, setHoverImage] = React.useState({})

  const mouseRef = React.useRef(null)

  const [boxSize, setBoxSize] = React.useState({ width: 0, height: 0 })

  const mouse = useMouse(mouseRef, {
    enterDelay: 100,
    leaveDelay: 100,
  })

  useAnimatableLayoutEffect(() => {
    if (mouseRef?.current) {
      const prop = AnimationState.getPositionOfElement(mouseRef)
      setBoxSize(prop)
    }
  }, [])

  return (
    <Chakra.Box
      ref={ref}
      width="100%"
      display="flex"
      position="relative"
      alignItems="flex-start"
      flexDirection="column"
      backgroundColor="transparent"
      minHeight="200px"
      css={{
        ".d_image": {
          opacity: 0,
          transition: "0.5s",
        },
      }}
      _hover={{
        ".d_image": {
          opacity: 1,
        },
      }}
    >
      <Chakra.Flex
        className="d_image"
        transition="0.05s"
        maxWidth="399px"
        maxHeight="280px"
        style={{
          transform: `translate(${mouse.x}px, ${
            mouse.y - boxSize.height / 2
          }px)`,
        }}
        css={{ transformOrigin: "center center" }}
        alignItems="center"
        justifyContent="center"
        position="absolute"
        overflow="visible"
        width="100%"
        height="100%"
      >
        <FadeTransition shouldChange={JSON.stringify(hoverImage)}>
          <PressLessImage
            width="100%"
            height="100%"
            maxWidth="399px"
            maxHeight="280px"
            objectFit="cover"
            image={hoverImage}
          />
        </FadeTransition>
      </Chakra.Flex>
      <Chakra.Box
        position="relative"
        ref={mouseRef}
        width="100%"
        display="grid"
        alignItems="flex-start"
        gridColumnGap="30px"
        gridTemplateColumns="repeat(2,1fr)"
        css={{
          "@media (max-width: 767px)": {
            gridTemplateColumns: "repeat(1,1fr)",
          },
          "@media (max-width: 479px)": {
            gridTemplateColumns: "repeat(1,1fr)",
          },
        }}
      >
        {props.clients.map(({ client, categoryLink, image }, index) => (
          <Chakra.Box
            as={categoryLink ? Link : undefined}
            to={categoryLink ? categoryLink : undefined}
            key={index}
            fontSize="36px"
            fontStyle="normal"
            fontFamily="Beausite 400"
            fontWeight="400"
            lineHeight="48px"
            onMouseOver={() => setHoverImage(image)}
          >
            {client}
          </Chakra.Box>
        ))}
      </Chakra.Box>
    </Chakra.Box>
  )
})

OurClients.displayName = "OurClients"

OurClients.pressless = {
  name: "Our Clients",
  attributes: {
    clients: {
      name: "Client Details",
      type: "Repeater",
      attributes: {
        client: {
          name: "Client Name",
          type: "RichText",
        },
        categoryLink: {
          name: "Client Link",
          type: "PlainText",
        },
        image: {
          name: "Hover Image",
          type: "Image",
          description: "This image will be shown at the side on hover.",
        },
      },
    },
  },
}
export default OurClients
