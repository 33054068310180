import React from "react"
import * as Chakra from "@chakra-ui/react";
import gsap from "gsap";
import ScrollTrigger from "gsap/ScrollTrigger";
import RxdLogo from "../../images/rxd-logo.svg";
import Section from "../section";
import { gsapClearRefs } from "../gsap-clear-refs";

interface RxdProps {
    children: React.ReactElement | React.ReactElement[]
}

const Rxd: PressLessComponent<RxdProps> = React.forwardRef<HTMLDivElement, RxdProps>(({ children }: RxdProps, ref) => {

    const logoAndCircleContainerRef = React.useRef<HTMLDivElement>();
    const childrenContainerRef = React.useRef<HTMLDivElement>();
    const logoRef = React.useRef<HTMLDivElement>();
    const circleRef = React.useRef<HTMLDivElement>();
    const circleContainerRef = React.useRef<HTMLDivElement>();

    const [isSsr, setIsSsr] = React.useState(true)
    React.useEffect(() => { setIsSsr(false) })

    React.useEffect(() => {
        gsap.registerPlugin(ScrollTrigger);

        let tl1: gsap.core.Timeline | null = null
        let tl2: gsap.core.Timeline | null = null

        if (logoAndCircleContainerRef?.current && childrenContainerRef?.current && logoRef?.current){

            ScrollTrigger.matchMedia({

                "(min-width: 768px)": () => {
                    tl1 = gsap.timeline({repeat: 0, repeatDelay: 0})

                    tl1.fromTo(circleRef.current, {clipPath: "circle(30% at 50% 100%)"}, {clipPath: "circle(100% at 50% 100%)", duration: 45}, 20)
                    tl1.fromTo(circleContainerRef.current, {yPercent: 0}, {yPercent: -30, duration: 45})
                    tl1.fromTo(circleContainerRef.current, {filter: "blur(0px)" }, {filter: "blur(40px)", duration: 10}, 27)
                    tl1.to(childrenContainerRef.current, {y: "-105px", duration: 30}, 30)
                    tl1.fromTo(childrenContainerRef.current, {opacity: 0}, {opacity: 1, duration: 30}, 50)
                    tl1.fromTo(logoRef.current, {filter: "blur(0px)"}, {filter: "blur(125px)", duration: 35}, 45)
                    tl1.fromTo(circleRef.current, {}, {}, 100)
                    ScrollTrigger.create({
                        trigger: logoAndCircleContainerRef.current,
                        endTrigger: childrenContainerRef.current,
                        markers: false,
                        start: "center center",
                        end: "bottom+=30% bottom",
                        pin: true,
                        scrub: 1,
                        pinReparent: false,
                        pinSpacing: false,
                        animation: tl1,
                        anticipatePin: 1,
                        invalidateOnRefresh: true,
                    })
                },
                "(max-width: 767px)": () => {
                    const refs = [circleRef, circleContainerRef, childrenContainerRef, logoRef, logoAndCircleContainerRef];
                    gsapClearRefs(refs);
                }
            })

        }


        return () => {
            if(tl1) {
                tl1.kill();
            }
            if(tl2){
                tl2.kill()
            }
        }

    }, []);

    return (
        <Chakra.Box
            ref={ref}
            width="100%"
            display="flex"
            position="relative"
            alignItems="flex-start"
            flexDirection="column"
            overflowX="clip"
            overflow={{base: "hidden", md: "visible"}}
            transition="opacity 1s"
            style={{opacity: isSsr ? 0 : 1}}
        >
            <Chakra.Box
                width="100%"
                position="relative"
                ref={logoAndCircleContainerRef}
                display="flex"
                justifyContent="center"
                flexDirection="column"
            >
                <Chakra.Box
                    mx="auto"
                    px="40px"
                    h="532px"
                    maxH={{base: "250px", md: "532px"}}
                    paddingTop={{base: "0", md: "31px"}}
                    paddingBottom="36px"
                    maxWidth="1800px"
                    width="100%"
                    css={{
                        "@media (max-width: 767px)": {
                            paddingLeft: "20px",
                            paddingRight: "20px",
                        },
                    }}
                    pos="relative"
                    zIndex={{base: "7", md: "3"}}
                    ref={logoRef}
                >
                    <RxdLogo width="100%" height="100%" />
                </Chakra.Box>
                <Chakra.Box
                    ref={circleContainerRef}
                    position={{base: "static", md: "absolute"}}
                    bottom={0}
                    mt={{base: "-160px", md: "0"}}
                    top="40vh"
                    left={0}
                    w="100vw"
                    h={{base: "80vh", md: "70vh"}}
                    maxH={{md: "calc(100vh + 200px)"}}
                    css={{
                        "@media screen and (min-width: 48em)": {
                            height: "42vw"
                        }
                    }}
                >
                    <Chakra.Box
                        w="100%"
                        h="100%"
                        bg="#EA1D25"
                        clipPath="circle(85% at 50% 100%)"
                        ref={circleRef}
                        css={{
                            "@media (max-width: 618px)": {
                                clipPath: "circle(95% at 50% 100%)"
                            },
                            "@media (max-width: 550px)": {
                                clipPath: "circle(100% at 50% 100%)"
                            },
                        }}
                    />
                </Chakra.Box>
            </Chakra.Box>
            <Chakra.Box
                w="100%"
                ref={childrenContainerRef}
                pos="relative"
                // top={{base: "40vh", md: "0"}}
                // left="0"
                pt={{base: "0", md: "40vh"}}
                mt={{base: "calc(-50px - 50vh)", md: "0"}}
                zIndex="5"
                overflow="hidden"
                background={{base: "#EA1D25", md: "none"}}
            >
                <Section>
                    {children}
                </Section>
            </Chakra.Box>
        </Chakra.Box>
    )
})

Rxd.pressless = {
    name: "RxD",
    attributes: {},
    hasChildren: true,
}

export default Rxd;
